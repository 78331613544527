import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex overflow-auto h-55px" }
const _hoisted_2 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "card mb-5 mb-xxl-8 employee-details" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: `/account/details/overview`,
            class: "nav-link text-active-dark me-6",
            "active-class": "active"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Overview ")
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_view)
        ])
      ])
    ])
  ], 64))
}