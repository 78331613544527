
import { defineComponent, onMounted } from 'vue'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'

export default defineComponent({
  name: 'account-settings',
  components: {
  },
  setup () {
    onMounted(() => {
      setCurrentPageTitle('Account Details')
      setCurrentPageTitleButton({})
    })
    return {
    }
  }
})
